import { navigate } from "@reach/router";
import React, { useEffect, useState } from "react";

import PaymentRazorpay from "../PaymentRazorpay/PaymentRazorpay";

import './OfflineOrders.scss';

const OfflineOrders=(props) =>{
    const [viewAllOrders, setViewAllOrders] = useState(false);
    const [filteredAllOtherOrders, setFilteredAllOtherOrders] = useState([]);
    const [filteredPaymentPendingOrders, setFilteredPaymentPendingOrders] = useState([]);
    
    const { offlineOrders } = props;
    
    useEffect(() => {
        window.scrollTo({
            top:0,
            left:0,
            behavior:'smooth'
        })
    }, [])
    
    useEffect(()=>{
        if(!offlineOrders){
            setFilteredAllOtherOrders([]);
            setFilteredPaymentPendingOrders([]);
            navigate('/')
        }else{
            setFilteredAllOtherOrders(filterAllOtherOrders(offlineOrders));
            setFilteredPaymentPendingOrders(filterPaymentPendingOrders(offlineOrders));
        }
    }, [offlineOrders])

    const filterPaymentPendingOrders=(orders)=>{
        const filter = orders.filter(order=>{
            if(order.orderStatus === 'payment_pending'){
                return order;
            }else{
                return null;
            }
        })
        return filter;
    }
    
    const filterAllOtherOrders=(orders)=>{
        const filter = orders.filter(order=>{
            if(order.orderStatus==='paid' || order.orderStatus==='cancelled'){
                return order;
            }else{
                return null;
            }
        })
        return filter;
    }
    
    if(offlineOrders){
        return(
            <section className='center-section'>
                <div className='sub-header align-left margin-auto-min-width-350'>
                    <h3 style={{marginLeft:'10px'}}>Orders</h3>
                </div>
                {   
                    offlineOrders.length?
                        filteredPaymentPendingOrders.length?
                            <div className='sub-header margin-auto-min-width-350'>
                                <span className='text-08 grey'>Tap on Pay Now to make payment for your order(s)</span>
                            </div>
                        :
                            <div className='sub-header margin-auto-min-width-350'>
                                <span className='text-08 grey'>No order(s) pending to pay</span>
                            </div>
                    : null
                }
                {
                    // offlineOrders?
                        offlineOrders.length?
                            <div className='list-of-orders-div margin-auto-min-width-350'>
                                {
                                    // offlineOrders.map(order=>{
                                    filteredPaymentPendingOrders.map(order=>{
                                        return(
                                            <div key={order.order_id} 
                                                className='list-element' 
                                                style={{
                                                    background:order.paymentStatus? '#ddd':'inherit'
                                                }}
    
                                            >
                                                <span className='text-small grey'>Order Ref: {order.order_id}</span>
                                                <div className='element-div'>
                                                    <div className='element-left'>
                                                        <span className='text-medium' style={{color:order.paymentStatus? 'grey':'inherit'}}>
                                                            <span style={{fontWeight:'600', fontSize:'1.1rem'}}>{order.currencySymbol} {order.amount}</span>
                                                        </span>
                                                        <span className='text-small grey'>{order.createdAt}</span>
                                                    </div>
                                                    <div className='element-right'>
                                                        {
                                                            order.paymentStatus?
                                                                <span style={{fontSize:'1.2rem', fontWeight:'600', color:'green' }}>Paid</span>
                                                            :
                                                                order.orderFrom==='razorpay'?
                                                                    <PaymentRazorpay 
                                                                        order_id={order.order_id}
                                                                        phone={props.phone}
                                                                        email={order.email}
                                                                        currencySymbol={order.currencySymbol}
                                                                        amount={order.amount}
                                                                    />
                                                                : null
                                                                
                                                                // <button className='action-button' style={{marginTop:'0'}}>Pay Now</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        : 
                            <div className='list-of-orders-div margin-auto-min-width-350'>
                                <span>No orders found</span>
                            </div>
                    // : null
                }
    
                {
                    filteredAllOtherOrders.length?
                        viewAllOrders? 
                            <div className='list-of-orders-div margin-auto-min-width-350'>
                                {   
                                    filteredAllOtherOrders.map(order=>{
                                        return(
                                            <div key={order.order_id} 
                                                className='list-element' 
                                                style={{
                                                    background:order.paymentStatus? '#ddd':'inherit'
                                                }}
    
                                            >
                                                <span className='text-small grey'>Order Ref: {order.order_id}</span>
                                                <div className='element-div'>
                                                    <div className='element-left'>
                                                        <span className='text-medium' style={{color:order.paymentStatus? 'grey':'inherit'}}>
                                                            <span style={{fontWeight:'600', fontSize:'1.1rem'}}>{order.currencySymbol} {order.amount}</span>
                                                        </span>
                                                        <span className='text-small grey'>{order.createdAt}</span>
                                                    </div>
                                                    <div className='element-right'>
                                                        {
                                                            order.paymentStatus?
                                                                <span style={{fontSize:'1.2rem', fontWeight:'600', color:'green' }}>Paid</span>
                                                            :
                                                                <button className='action-button' style={{marginTop:'0'}}>Pay Now</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })                                
                                }
                                <div className='pointer text-medium blue pa-top-5 align-right' style={{paddingRight:'10px'}} onClick={()=>setViewAllOrders(false)}>
                                    <span>Hide</span>
                                </div>
                            </div>
                        : 
                            <div className='list-of-orders-div margin-auto-min-width-350'>
                                <div className='pointer text-medium blue pa-top-5 align-right' style={{paddingRight:'10px'}} onClick={()=>setViewAllOrders(true)}>
                                    <span>View more</span>
                                </div>
                            </div>
                    : null
                }
    
            </section>
        )
    }else{
        return null;
    }
}

export default OfflineOrders;