import React from "react";
import { razorpayPopupTitleForOnline } from "../Constants/Constants";
// import { firestore } from "../../firebase/firebaseConfig";

const PaymentRazorpay =({ currencySymbol, phone, email, amount, order_id, orderType }) =>{

    const handleResponseSuccess=(response)=>{
        // await firestore('/')
        alert(response.razorpay_payment_id);
    }

    let options = {
        "key": "rzp_test_iWb5T4g1frKvuw", 
        // Enter the Key ID generated from the Dashboard    
        // "amount": Number(totalCartValue)*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise    
        // "currency": "INR",    
        "name": orderType==='online'? razorpayPopupTitleForOnline:"",
        // "description": "Test description",
        // "image": "https://example.com/your_logo",    
        "order_id": order_id,
        "handler": (response)=>handleResponseSuccess(response),
        // "handler": function (response){
        //     alert(response.razorpay_payment_id);        
        //     alert(response.razorpay_order_id);        
        //     alert(response.razorpay_signature);
        // },
        "prefill": {
            // "name": "admin",
            "email": email,        
            "contact": phone
        },
        // "notes": {
        //     "address": "Razorpay Corporate Office"
        // },
        "theme": {
            // "color": "rgba(0, 128, 0, 0.2)",
            "color": "rgb(0, 100, 18)"
        }
    }
    const rzp1 = new window.Razorpay(options);
    rzp1.on('payment.failed', function (response){        
        alert(response.error.code);        
        alert(response.error.description);        
        alert(response.error.source);        
        alert(response.error.step);        
        alert(response.error.reason);        
        alert(response.error.metadata.order_id);        
        alert(response.error.metadata.payment_id);
    });

    const paymentButtonOnClick=(e) =>{
        // document.getElementById('rzp-button1').onclick = function(e){    
            rzp1.open();    
            e.preventDefault();
        // }
    }
    // {/* {
        // AddLibrary('https://checkout.razorpay.com/v1/checkout.js')
    // } */}
    return(
        <button id='rzp-button1' 
            className='action-button'
            style={{marginTop:0}} 
            // disabled={
            //     paymentSummary? (proceedTo==='payment'? false: true): true
            // } 
            onClick={(e)=>paymentButtonOnClick(e)}
        >
            Pay Now
        </button>
    )
}

export default PaymentRazorpay;