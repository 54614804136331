import React, {useState, useEffect} from "react";
import OtpInput from 'react-otp-input';
import { navigate } from "@reach/router";

const PhoneValidation =(props) =>{
    const [email, setEmail] = useState('');     // to store email change
    const [phone, setPhone] = useState('');     // to store phone number change
    const [pin, setPin] = useState('');         // to store pin change
    const [forgot, setForgot] = useState(false); // to handle if the user clicked on the forgot pin, show a msg based on the status
    const [otpSent, setOtpSent] = useState(false); // to handle if the user clicked on resend OTP
    const [otp, setOtp] = useState('');         // to store OTP change
    // const [resend, setResend] = useState(false);

    const validatePhone =(e) =>{
        e.preventDefault();
        console.log('ValidatePhone - ', phone);
        setOtpSent(true);
    }

    const handleChange =(name, value)=>{
        switch(name){
            case 'otp': setOtp(value);
                        break;
            case 'pin': setPin(value);
                        break;
            default: break;
        }
    }

    const verifyOtp=(e)=>{
        e.preventDefault();
        console.log('OTP entered is ', otp);

        // if otp is correct fetch offline orders from firecloud
        const offlineOrders = [
            {
                order_id:'order_J2bhl32mpCPCNw',
                amount: 4500,
                createdAt: 'Mon 28 Feb 2022',
                currencySymbol: '₹',
                displayName:'ravi sharma',
                email:'rvshrm01@gmail.com',
                orderFrom:'razorpay',
                orderStatus:'payment_pending',
                paymentStatus:false,
            },
            {
                order_id:'ord_ravi_old',
                amount: 18400,
                createdAt: 'Mon 21 Feb 2022',
                currencySymbol: '₹',
                displayName:'ravi sharma',
                email:'rvshrm01@gmail.com',
                orderFrom:'razorpay',
                orderStatus:'paid',
                paymentStatus:true,
            },
            {
                order_id:'ord_dkjssmnsdSADFkds12a232',
                amount: 1735,
                createdAt: 'Tue 22 Feb 2022',
                currencySymbol: '₹',
                displayName:'ravi sharma',
                email:'rvshrm01@gmail.com',
                orderFrom:'razorpay',
                orderStatus:'paid',
                paymentStatus:true,
            },
            // {
            //     order_id:'order_J2biSB4rLqoeXw',
            //     amount: 1699,
            //     createdAt: 'Wed 23 Feb 2022',
            //     currencySymbol: '₹',
            //     displayName:'ravi sharma',
            //     email:'rvshrm01@gmail.com',
            //     orderFrom:'razorpay',
            //     orderStatus:'payment_pending',
            //     paymentStatus:false,
            // },

        ]
        props.setPhone(phone);
        props.setOfflineOrders(offlineOrders);
        // props.setOfflineOrders([]);
    }

    const containerStyle={
        width:'100%',
        display:'flex',
        justifyContent:'space-evenly',
        alignItems:'center'
    }
    const inputStyle={
        background:'#eee',
        padding:'10px',
        borderRadius:'5px',
        // border:'none',
        border:'1px solid #bbb',
        fontSize:'1.2rem'
    }

    const [counter, setCounter] = useState(30);

    useEffect(() => {
        if(otpSent && counter){
            const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            return () => clearInterval(timer);
        }
    }, [counter, otpSent]);

    useEffect(() =>{
        if(props.offlineOrders){
            navigate('/orders');
        }
    }, [props.offlineOrders]);

    // If user Forgot PIN -- verify if the user email exists? then send an email to the user with the saved pin, --
    // :: but in any case donot let the user know if the email id exists in the database. 
    // Show a fixed msg : If this email exists, you will receive an email with your PIN shortly.
    const forgotPin=()=>{
        setForgot(true);
    };
    
    const resendOtp=()=>{
        setOtp('');
        setCounter(30);
        console.log('resend otp success')
    };
    // if(props.offlineOrders){
    //    <Redirect noThrow from='/*' to='/orders' />
    // }else{
        return(
            <div style={{display:'flex', flexDirection:'column', height:'60%', background:'none'}}>
                <h3 style={{color:'#1c271d'}}>Verify your contact information</h3>
                {
                    otpSent?
                        <div>
                            {/* <span style={{fontSize:'0.8rem', color:'#888'}}>Please enter the OTP received on <span style={{color:'rgb(28, 134, 160)'}}>{props.countryCode} {phone}</span></span> */}
                            <span style={{fontSize:'0.8rem', color:'#888'}}>Enter your <span style={{color:'rgb(28, 134, 160)'}}>6 digit PIN</span></span>
    
                            <div style={{paddingTop:'50px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <form onSubmit={(e)=>verifyOtp(e)} 
                                    className='form-phone-verification'
                                >
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <OtpInput 
                                            value={pin}
                                            onChange={(value)=>handleChange('pin', value)}
                                            numInputs={6}
                                            separator={''}
                                            shouldAutoFocus={true}
                                            containerStyle={containerStyle}
                                            inputStyle={inputStyle}
                                            isInputNum={true}
                                        />
                                        {/* <input
                                            style={inputStyle}
                                            type='text'
                                            value={otp}
                                            onChange={(e)=>handleOtpChange(e.target.value)}
                                            minLength='6'
                                            maxLength='6'
                                        /> */}
                                    </div>
    
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-end'}}>
                                        <div style={{width:'180px',  textAlign:'left', lineHeight:'1', paddingBottom:'15px'}} id='resend-otp-div'>
                                            {/* {
                                                counter? 
                                                    <span style={{fontSize:'0.8rem', color:'#888'}}>
                                                        Resend OTP in {counter}s
                                                    </span>
                                                : 
                                                    <button type='button' 
                                                        onClick={()=>{
                                                            if(counter<=0){
                                                                resendOtp();
                                                            }
                                                        }}
                                                        style={{fontSize:'0.8rem', color:'rgb(28, 134, 160)', background:'none', padding:'5px 5px', border:'none', cursor:'pointer'}}
                                                    >
                                                        Resend OTP
                                                    </button>
                                                } */}
                                            <button type='button' 
                                                onClick={()=>{
                                                    if(counter<=0){
                                                        forgotPin();
                                                    }
                                                }}
                                                style={{fontSize:'0.8rem', color:'rgb(28, 134, 160)', background:'none', padding:'5px 5px', border:'none', cursor:'pointer'}}
                                            >
                                                Forgot PIN
                                            </button>
                                        </div>
                                        <button type='submit' id='verify-otp' disabled={pin.length===6? false:true}>Verify</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    : 
                        <div>
                            {/* <span style={{fontSize:'0.8rem', color:'#888'}}>Verification code will be sent to this number</span> */}
                            <span style={{fontSize:'0.8rem', color:'#888'}}>You will be asked to enter your pin on the next screen</span>
    
                            <div style={{paddingTop:'50px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <form onSubmit={(e)=>validatePhone(e)} 
                                    className='form-phone-verification'
                                >
                                    
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div style={{background:'#eee', borderRadius:'10px', padding:'10px 15px'}}>
                                            <span style={{fontSize:'1.2rem', color:'#1c271d'}}>Email</span>
                                        </div>
                                        <input 
                                            className='input-phone'
                                            style={{background:'none', maxWidth:'200px'}}
                                            type='email'
                                            value={email}
                                            onChange={(e)=>setEmail(e.target.value.trim())}
                                            placeholder='yours@email.com'
                                            required
                                            autoFocus
                                        />
                                    </div>
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div style={{background:'#eee', borderRadius:'10px', padding:'10px 15px'}}>
                                            <span style={{fontSize:'1.2rem', color:'#1c271d'}}>{props.countryCode}</span>
                                        </div>
                                        <input 
                                            className='input-phone'
                                            style={{background:'none', maxWidth:'200px'}}
                                            type='tel'
                                            value={phone}
                                            onChange={(e)=>setPhone(e.target.value.trim())}
                                            placeholder='Phone Number'
                                            required
                                            minLength='10'
                                            maxLength='10'
                                        />
                                    </div>
                                    <div style={{width:'100%', marginTop:'30px'}}>
                                        {/* Cloudflare Turnstile Captcha */}
                                        <div className="cf-turnstile" data-sitekey="0x4AAAAAAABKg4TTfM2J94rP" data-theme="light"></div>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-end'}}>
                                        <div style={{width:'180px',  textAlign:'left', lineHeight:'1'}}>
                                            {/* <span style={{fontSize:'0.8rem', color:'#888'}}>Verification code will be sent to this number</span> */}
                                        </div>
                                        <button type='submit' id='submit-phone' disabled={phone.length===10? false:true}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
    
                }
            </div>
        )
    // }
}

export default PhoneValidation;