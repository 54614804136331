import React, {useState, useEffect} from "react";
import { API_URL, freeCancellationPeriod, imgSrvShop, myOrders, refundsAndCancellation, showTelephone } from "../Constants/Constants";
import PaymentRazorpay from "../PaymentRazorpay/PaymentRazorpay";
import {ReactComponent as BackArrow} from "../../assets/svg/back_arrow.svg";
import {ReactComponent as HeartCircle} from "../../assets/svg/emoji/heart_emoji_circle.svg";

import './PayOrderOn.scss';
import { QuestionRatingEmoji } from "../Questions/Questions";
import LoadingCircleDiv from "../Loading/LoadingCircleDiv";
import { firestore } from "../../firebase/firebaseConfig";

export function getImageUrl(size, uuid, imgNo){
    // ***********************************************************************************************************************
    // ****** Only images with a .png must be used,  because .png is a constant while creating the url which will be returned
    // ***********************************************************************************************************************
    
    // requires imageName for eg. if name of the image is 1.png then only provide "1" for the parameter imgNo
    // returns a url on which the image for this product will be available with the specified size
    return(`${imgSrvShop}/${uuid}/images/size/${size}x${size}/${imgNo}.png`);
}

const PayOrderOn = (props) =>{
    const [warning, setWarning] = useState(true);
    const [feedbackForm, setFeedbackForm] = useState(false);
    const [ratingValue, setRatingValue] = useState(null);
    const [feedbackDescription, setFeedbackDescription] = useState('');
    const [feedbackCategory, setFeedbackCategory] = useState('issue');
    const [loadingSubmitFeedback, setLoadingSubmitFeedback] = useState(false);
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false);
    const [pageMsg, setPageMsg] = useState('');
    
    const [order, setOrder] = useState(null);
    // const [order, setOrder] = useState({
    //     amount:1468,
    //     bagItems: [
    //         {
    //             craft_items_category:{
    //                 category_id:1,
    //                 category_name: "devotional"
    //             },
    //             enabled: true,
    //             id:1,
    //             item_images_500:1,
    //             item_name: "ganesh murti",
    //             item_qty:1,
    //             item_qty_unit: "unit",
    //             item_threshold_qty:5,
    //             msr_unit: "inch",
    //             msr_x:12,
    //             msr_y:5,
    //             price_new: 799,
    //             price_old: 1499,
    //             prod_uuid: "1bc789d6-9b74-4a01-8220-248aaa5344ed",
    //             weight: 1.2,
    //             weight_unit: "kg"
    //         },
    //         {
    //             craft_items_category:{
    //                 category_id:6,
    //                 category_name: "home decor"
    //             },
    //             enabled: true,
    //             id:15,
    //             item_images_500:1,
    //             item_name: "Elephant",
    //             item_qty:2,
    //             item_qty_unit: "unit",
    //             item_threshold_qty:4,
    //             msr_unit: "inch",
    //             msr_x:8,
    //             msr_y:4,
    //             price_new: 859,
    //             price_old: 1199,
    //             prod_uuid: "14160990-2a78-4827-8bb7-701911f1bbad",
    //             weight: 1.8,
    //             weight_unit: "kg"
    //         }
    //     ],
    //     createdAt: "Mon 05 Dec 2022",
    //     displayName:"irm work",
    //     email: "irm.envoq@gmail.com",
    //     orderFrom:"razorpay",
    //     orderStatus: "created",
    //     response: {
    //         amount: 146800,
    //         amount_due: 146800,
    //         amount_paid: 0,
    //         attempts: 0,
    //         createdAt:1670338759,
    //         currency: "INR",
    //         entity: "order",
    //         id: "order_KofLm7xPGMqeo0",
    //         notes:{
    //             cstmrUid: "bxOE1DtGcYaJuX7LujZchdqGyWy1",
    //             orderType:"online"
    //         },
    //         offer_id: null,
    //         receipt: "1GQK2jfKaDEQn3Rnnn58",
    //         status: "paid"
    //     },
    //     uid:"bxOE1DtGcYaJuX7LujZchdqGyWy1"
    // });

    useEffect(() => {
        window.scrollTo({
            top:0,
            left:0,
            behavior:'smooth'
        })
        
        // Fetch payOrder
        async function fetchData(id){
            console.log("Fetching pay order")
            try{
                const doc = await firestore.doc(`payOrdersOn/${id}`).get();
                if(!doc.exists){
                    setLoadingPage(false);
                    setPageMsg('No record found');
                    setOrder(null);
                }else{
                    setLoadingPage(false);
                    setOrder(doc.data());
                }
            }catch(err){
                console.log('Error::FetchPayOrder::', err);
                setLoadingPage(false);
                setOrder(null);
                setPageMsg('Something went wrong, please try again after some time');
            }
        }
        setLoadingPage(true);
        fetchData(props.id);
    }, [])

    const sendFeedback=()=>{
        if(ratingValue!==null && feedbackDescription.length>3){
            setLoadingSubmitFeedback(true);
            // console.log('sendFeedback()');
            // Cloud function to write feedback to planetScale db
            fetch(API_URL+"/addShoppingFeedback", {
                method:'post',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    feedback: feedbackDescription,
                    feedbackType: feedbackCategory,
                    shopExperienceRating: ratingValue,
                    orderId: order.response.id,
                    customerUid: order.response.notes.cstmrUid
                })
            }).then(response=>response.json())
            .then(data=>{
                if(data.status===1){
                    // stop loading animation and show feedback submitted msg
                    // also empty feedback description, ratingValue
                    setLoadingSubmitFeedback(false);
                    setFeedbackSubmitted(true);
                    setFeedbackDescription('');
                    setRatingValue(null);
                }else{
                    // stop loading animation and show an error msg that feedback could not be submitted
                    setLoadingSubmitFeedback(false);
                    setFeedbackSubmitted(false);
                }
            })
            .catch(error=>{
                console.log("Something went wrong")
                setLoadingSubmitFeedback(false);
                setFeedbackSubmitted(false);
            })
        }
    }

    return(
        <div style={{marginTop:'-60px'}}>
            {
                loadingPage? 
                    <div className="flex-c-c" style={{height:'50vh'}}>
                        <LoadingCircleDiv scale='2.5' center={true}/>
                    </div>
                : 
                    pageMsg.length?
                        <div className="flex-c-c" style={{height:'50vh'}}>
                            <span>{pageMsg}</span>
                        </div>
                    : 
                        order!==null?
                            <DisplayOrderInfo 
                                warning={warning}
                                setWarning={setWarning}
                                order={order}
                                feedbackForm={feedbackForm}
                                setFeedbackForm={setFeedbackForm}
                                feedbackSubmitted={feedbackSubmitted}
                                loadingSubmitFeedback={loadingSubmitFeedback}
                                sendFeedback={sendFeedback}
                                feedbackCategory={feedbackCategory}
                                setFeedbackCategory={setFeedbackCategory}
                                ratingValue={ratingValue}
                                setRatingValue={setRatingValue}
                                feedbackDescription={feedbackDescription}
                                setFeedbackDescription={setFeedbackDescription}
                            />
                        : null
            }
        </div>
    )
}

const DisplayOrderInfo=(props)=>{
    const { warning, setWarning, order, feedbackForm, setFeedbackForm, feedbackSubmitted, loadingSubmitFeedback, sendFeedback,
        feedbackCategory, setFeedbackCategory, ratingValue, setRatingValue, feedbackDescription, setFeedbackDescription} = props;
    if(order.displayName){
        return(
        <>
            {
                warning && order.response.status==='created' ?
                    <section style={{position:'fixed', top:'55px', left:'0px', display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%', background:'rgba(255,255,255,0.4)', backdropFilter:'blur(8px)'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', flexDirection:'column'}}>
                            <div className='warning-notice'> 
                                <div className='warning-notice-title'>
                                    <div style={{width:'20px', height:'20px', borderRadius:'50%', background:'darkred', marginRight:'10px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <span style={{color:'orange'}}>!</span>
                                    </div>
                                    <span>Warning&nbsp;</span>
                                </div>
                                <div className='warning-notice-msg'>
                                    <span>
                                        {/* <span style={{fontWeight:'600', fontStyle:'italic'}}>Only pay if you know where the order is being delivered.</span>&nbsp; */}
                                        <span>This order will be delivered to the pre-selected address.</span>
                                        <br/>
                                        <span style={{fontWeight:'600', fontStyle:'italic'}}>Any change in delivery address after the payment is not allowed</span>
                                        {/* <span>This order will be delivered to the pre-selected address.&nbsp;<span style={{fontWeight:'600', fontStyle:'italic'}}>Any change in delivery address after the payment is not allowed</span></span> */}
                                    </span>
                                    <br/>
                                    <button className="outline-button" 
                                        style={{fontSize:'0.9rem', fontWeight:'600', width:'100px'}}
                                        onClick={()=>setWarning(false)}
                                    >
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                : null
            }
            {
                order.response.status==='paid'?
                    <section style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'30px'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0px 20px', width:'100%', maxWidth:'600px', flexDirection:'column'}}>
                            <div style={{textAlign:'flex', justifyContent:'flex-end', alignItems:'center', width:'100%'}}>
                                <div style={{marginBottom:'30px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                    <a href={myOrders} className="action-button" style={{background:'none', border:'2px solid black', color:'black', fontSize:'0.9rem', fontWeight:'600', display:'flex', justifyContent:'space-between', width:'100px', alignItems:'center', textDecoration:'none'}}>
                                        <div style={{width:'20px', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <BackArrow />
                                        </div>
                                        <span>My Orders</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                : null
            }
            <section style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'30px'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0px 20px', width:'100%', maxWidth:'600px', flexDirection:'column'}}>
                    <div style={{width:'100%', marginBottom:'30px', textAlign:'left'}}>
                        <div style={{borderBottom:'1px solid #ccc', paddingBottom:'10px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                            {
                                order.response.status==='paid'?
                                    <span style={{fontWeight:'600', fontSize:'1.2rem'}}>₹ {order.amount}</span>
                                : 
                                    <span style={{fontSize:'0.8rem'}}>Welcome <span style={{textTransform:'capitalize', fontStyle:'italic', fontSize:'1.1rem'}}><b>{order.displayName.split(' ')[0]}</b></span>,</span>

                            }
                            <div style={{textAlign:'right'}}>
                                <span style={{fontSize:'0.8rem'}}>For any queries<br/><span style={{fontSize:'0.8rem', fontWeight:'600'}}>{showTelephone}</span></span>
                            </div>
                        </div>
                        {
                            order.response.status==='paid' ?
                                <div style={{textAlign:'center', background:'rgba(255,255,0,0.2)'}}>
                                    <span>
                                        😀<span style={{fontStyle:'italic', fontSize:'0.7rem'}}><b>Thank you</b></span><span style={{fontStyle:'italic', fontSize:'0.7rem'}}>, now its our turn. We will make sure </span>🚀<span style={{fontStyle:'italic', fontSize:'0.7rem'}}><b>fast</b> & </span>🦺<span style={{fontStyle:'italic', fontSize:'0.7rem'}}><b>secure</b></span> <span style={{fontStyle:'italic', fontSize:'0.7rem'}}>delivery for the order</span>
                                    </span>
                                    {/* <br/> */}
                                    {/* <div style={{textAlign:'right'}}>
                                        <span style={{fontSize:'0.8rem'}}>For any queries call<br/><span style={{fontSize:'1rem', fontWeight:'600'}}>{showTelephone}</span></span>
                                    </div> */}
                                </div>
                            : 
                                <div style={{textAlign:'center', background:'rgba(255,255,0,0.2)'}}>
                                    <span>
                                        <span style={{fontStyle:'italic', fontSize:'0.7rem'}}>Complete the order</span> 🚀 <span style={{fontStyle:'italic', fontSize:'0.7rem'}}>and we will handle the rest</span> 😀
                                    </span>
                                    {/* <br/> */}
                                    {/* <div style={{textAlign:'right'}}>
                                        <span style={{fontSize:'0.8rem'}}>For any queries call<br/><span style={{fontSize:'1rem', fontWeight:'600'}}>{showTelephone}</span></span>
                                    </div> */}
                                </div>
                        }
                    </div>
                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', textAlign:'left'}}>
                        {/* <div style={{display:'flex', flexDirection:'column'}}>
                            <span style={{fontSize:'0.7rem'}}>Order Ref: bdsjkSDKDSSOdhsoP</span>
                            <div>
                                <span style={{fontSize:'0.8rem'}}>Status: <b>Pending</b></span>
                            </div>
                        </div> */}
                        {
                            order.response.status!=='paid' ?
                                <div style={{display:'flex', alignItems:'center'}}>
                                    {/* <span style={{fontSize:'1.2rem'}}>Total&nbsp;&nbsp;</span> */}
                                    <span style={{fontWeight:'600', fontSize:'1.2rem'}}>₹ {order.amount}</span>
                                </div>
                            : null
                        }
                        {
                            order.response.status==='paid'?
                                <div className='flex-row-column-media-600' style={{width:'100%', borderBottom:'1px solid #ccc', paddingBottom:'30px'}}>
                                    <div style={{padding:'0px 20px'}}>
                                        <p style={{fontSize:'0.7rem', textAlign:'justify'}}>
                                            {
                                                freeCancellationPeriod ?
                                                    <span>
                                                        <b>No cancellation charges will be deducted</b>,&nbsp;
                                                        {/* <br/> */}
                                                        if you cancel your order within <b>{freeCancellationPeriod} hr{freeCancellationPeriod>1?'s':''}</b>
                                                    </span>
                                                : 
                                                    <span>
                                                        <b>Need help? Get in touch with us</b>
                                                    </span>
                                            }
                                            <br/>
                                            For more information regarding cancellation charges, check out <a className='blue-link' href={refundsAndCancellation} target='_blank' rel='noreferrer' >Refunds & Cancellation</a>
                                        </p>
                                    </div>
                                    <div className='flex-row-column-partition'>
                                        <div style={{width:'200px', height:'40px', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:'4px', background:'green', color:'white'}}>
                                            <span>Paid
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            :
                                <div style={{height:'100%'}}>
                                    <PaymentRazorpay 
                                        order_id={order.response.id}
                                        phone={order.phone}
                                        email={order.email}
                                        currencySymbol={'₹'}
                                        amount={order.amount}
                                        orderType={order.response.notes.orderType}
                                    />
                                </div>
                        }
                        
                    </div>
                    {
                        order.response.status==='paid'? null :
                            <div style={{display:'flex', flexDirection:'column', borderTop:'1px solid #ccc', marginTop:'20px', width:'100%'}}>
                                <span style={{textAlign:'left', paddingTop:'10px', fontWeight:'600', fontSize:'0.8rem'}}>Order Details</span>
                                {
                                    order.bagItems.map((item, index)=>{
                                        return(
                                            <div key={index} className="order-items-info">
                                                <div className="item-image" style={{backgroundImage:`url(${getImageUrl(300, item.prod_uuid, 1)})`, backgroundPosition:'center'}}></div>
                                                <div className="item-info">
                                                    <span className="item-name">{item.item_name}</span>
                                                    <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                                        <div style={{border:'1px solid black', borderRadius:'4px', padding:'0px 5px', width:'30px', display:'flex', justifyContent:'center', marginRight:'5px'}}>
                                                            <span>
                                                                <b>{item.item_qty}</b>
                                                            </span>
                                                        </div>
                                                        <span>{item.item_qty_unit}(s)</span>
                                                    </div>
                                                </div>
                                            </div>            
                                        )
                                    })
                                }
                            </div>
                    }
                        {/* <div className="order-items-info">
                            <div className="item-image"></div>
                            <div className="item-info">
                                <span className="item-name">Ganesh Murti</span>
                                <div>
                                    <span>Qty: 1 unit</span>
                                </div>
                            </div>
                        </div>
                        <div className="order-items-info">
                            <div className="item-image"></div>
                            <div className="item-info">
                                <span className="item-name">Table Clock</span>
                                <div>
                                    <span>Qty: 2 unit</span>
                                </div>
                            </div>
                        </div> */}
                    {/* </div> */}
                    
                </div>
            </section>
            <section style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'30px'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0px 20px', width:'100%', maxWidth:'600px', flexDirection:'column'}}>
                    <div style={{textAlign:'flex', justifyContent:'flex-end', alignItems:'center', width:'100%'}}>
                        {/* <div style={{marginBottom:'30px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                            <a href={myOrders} className="action-button" style={{background:'none', border:'2px solid black', color:'black', fontSize:'0.9rem', fontWeight:'600', display:'flex', justifyContent:'space-between', width:'100px', alignItems:'center', textDecoration:'none'}}>
                                <div style={{width:'20px', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <BackArrow />
                                </div>
                                <span>My Orders</span>
                            </a>
                        </div> */}
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center', height:'100%', paddingTop:'30px'}}>
                            {
                                feedbackForm? null:
                                    <span style={{fontSize:'0.8rem'}}>Tell us about your experience shopping with us, and help us improve</span>
                            }
                            <br/>
                            {
                                feedbackForm? null :
                                    <span className="blue-link" style={{fontSize:'0.8rem', cursor:'pointer'}} onClick={()=>setFeedbackForm(true)}>
                                        Share Feedback
                                    </span>
                            }
                        </div>
                    </div>
                    {
                        feedbackForm?
                            <div style={{background:'rgba(0,0,0,0.05)', width:'100%', paddingBottom:'20px'}}>
                                {
                                    feedbackSubmitted && !loadingSubmitFeedback? 
                                        <div className="flex-c-c" style={{flexDirection:'column', margin:'20px', textAlign:'center'}}>
                                            <div className="flex-c-c" style={{width:'150px' , justifyContent:'space-around'}}>
                                                <div style={{width:'30px'}}>
                                                    <HeartCircle />
                                                </div>
                                                <span style={{fontWeight:'600', fontSize:'1.2rem'}}>Thank you</span>
                                            </div>
                                            <span style={{fontSize:'0.8rem'}}>We have received your valuable feedback</span>
                                        </div>
                                    :
                                        <div style={{textAlign:'left', margin:'20px'}}>
                                            <span style={{fontWeight:'600', fontSize:'1.2rem'}}>We value your feedback</span><br/>
                                            {
                                                loadingSubmitFeedback? 
                                                    <span style={{fontSize:'0.8rem'}}>Submitting your feedback</span>
                                                :
                                                    <span style={{fontSize:'0.8rem'}}>Share your feedback and help us improve</span>
                                            }
                                        </div>
                                }
                                {
                                    loadingSubmitFeedback?
                                        <div style={{position:'relative', height:'10px', marginBottom:'30px'}}>
                                            <LoadingCircleDiv center={true} scale='1.2'/>
                                        </div>
                                    :
                                        feedbackSubmitted? null:

                                            <form onSubmit={(e)=>{
                                                e.preventDefault(); 
                                                sendFeedback();
                                                }}
                                            >
                                                <div style={{width:'100%'}}>
                                                    {/* Feedback form Select Category */}
                                                    <span style={{marginRight:'20px'}}>Select Category</span>
                                                    <select onChange={(e)=>setFeedbackCategory(e.target.value)}
                                                        name="feedback"
                                                        style={{padding:'5px', fontSize:'1rem', background:'none', cursor:'pointer'}}
                                                        id="feedback"
                                                        value={feedbackCategory}
                                                        autoFocus
                                                    >
                                                        <option value="issue">Issue</option>
                                                        <option value="suggestion">Suggestion</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                </div>
                                                <br/>
                                                <div style={{width:'100%'}}>
                                                    {/* Feedback form Know More... */}
                                                    <textarea style={{background:'none', width:'80%', maxWidth:'80%', margin:'0 auto', height:'60px', fontSize:'1rem', padding:'0px 20px'}} 
                                                        placeholder='We want to know more...'
                                                        onChange={(e)=>setFeedbackDescription(e.target.value)}
                                                        minLength={3}
                                                    />
                                                </div>
                                                <QuestionRatingEmoji 
                                                    question='How was your experience placing the order' 
                                                    ratingValue={ratingValue}
                                                    setRatingValue={setRatingValue}    
                                                />
                                                {/* <div>
                                                    <span style={{marginRight:'20px'}}>Rate your experience for placing an order</span>
                                                    <div>
                                                        
                                                    </div>
                                                </div> */}
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                    <button className='outline-button' 
                                                        type='button' 
                                                        style={{fontSize:'0.8rem', marginRight:'20px'}} 
                                                        onClick={()=>{setRatingValue(null); setFeedbackForm(false)}}    
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button className="action-button" 
                                                        type='submit' 
                                                        disabled={ratingValue!==null && feedbackDescription.length>3? false:true}
                                                        style={{fontSize:'0.8rem'}}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                }
                            </div>
                        : null
                    }
                </div>
            </section>
        </>
        )
    }else{
        return null;
    }
}
export default PayOrderOn;