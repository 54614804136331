import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyBZPSYQuFLUF-vsfm9lTs4pi8GfJzaNf9w",
  authDomain: "sgmarbles.firebaseapp.com",
  databaseURL: "https://sgmarbles.firebaseio.com",
  projectId: "sgmarbles",
  storageBucket: "sgmarbles.appspot.com",
  messagingSenderId: "355095309843",
  appId: "1:355095309843:web:4cc6c802175acbc62d59e6"
  };

firebase.initializeApp(config);

export const firestoreTimestamp = firebase.firestore.FieldValue.serverTimestamp();
export const firestore = firebase.firestore();
export const auth = firebase.auth();

export const createUserProfileDocument = async (userAuth, additionalData) =>{
  if(!userAuth) return;

  const userRef = firestore.doc(`customersOn/${userAuth.uid}`);
  const snapShot = await userRef.get();
  if(!snapShot.exists){
    const { displayName, email, uid } = userAuth;
    const createdAt = firestoreTimestamp;
    
    // console.log('firebase-userauth:', userAuth)
    try{
      await userRef.set({
        displayName,
        contact:null,
        uid,
        createdAt,
        email
      })
    }catch(error){
      console.log(error);
    }
  }
  return userRef;
}


const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle =() => auth.signInWithPopup(provider);



export default firebase;