import React from 'react';
import Logo from '../../assets/svg/logo_circle.svg';
import {webTitle, subtitle, websiteURL} from '../Constants/Constants';

import './Header.scss';

const Header =(props)=>{

    return(
        <div className='header'>
            <a href={websiteURL} style={{textDecoration:'none'}}>
                <div id='header-logo-container'>
                    <img id='header-logo' src={Logo} alt='logo'/>
                    {/* <span style={{fontWeight:600, color:'#444'}}>Pay</span> */}
                </div>
            </a>
        </div>
    )
}

export default Header;