import React from 'react';
import './Loading.scss';

const LoadingCircleDiv =(props) =>{
    if(props.center){
        return(
            <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <div className='loading-circle' style={{transform: props.scale? `scale(${props.scale})`:null}}>
                    <div className='inner-circle'></div>
                    <div className='rotary-circle rotate'></div>
                </div>
            </div>
        )
    }else{
        return(
            <div style={{position:'absolute', left:props.left? props.left:'25px'}}>
                <div className='loading-circle'>
                    <div className='inner-circle'></div>
                    <div className='rotary-circle rotate'></div>
                </div>
            </div>
        )
    }
}

export default LoadingCircleDiv;