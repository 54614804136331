import React, {useState} from 'react';
// import { Helmet } from 'react-helmet';
// import './component/Shop/Shop.scss';
import { privacyPolicy, terms, title, contact, refundsAndCancellation } from './component/Constants/Constants';
import './App.scss';
import Header from './component/Header/Header';
import { Router } from '@reach/router';
import PhoneValidation from './component/PhoneValidation/PhoneValidation';
import OfflineOrders from './component/OfflineOrders/OfflineOrders';
import PayOrderOn from './component/OnlineOrders/PayOrderOn';
// import { Link } from '@reach/router';
// import { ReactComponent as Logo } from './assets/svg/logo_circle.svg'

const App =() =>{
  const [offlineOrders, setOfflineOrders] = useState(null);
  const [phone, setPhone] = useState('');

  return(
    // <div style={{background:'#1c271d', height:'100vh', width:'100vw'}}>
    <div className='App'>
      <div>
        <Header />           
      </div>
      <section style={{paddingTop:'150px', paddingBottom:'150px', background:'#eee'}}>
        <Router>
          <PhoneValidation 
            path='/*'
            countryCode='+91'
            setOfflineOrders={setOfflineOrders}
            offlineOrders={offlineOrders}
            setPhone={setPhone}
          />
          {/* <MakePayment 
            path='/pay/:payorder_id'
          /> */}
          <PayOrderOn 
            path='/pay/:id'
          />
          <OfflineOrders 
            path='/orders/*'
            offlineOrders={offlineOrders}
            phone={phone}
          />
          {/* <OfflineOrderPayment
            path='/link/:order_id'
          /> */}
        </Router>
      </section>

      <div className='footer'>
        <div className='footer-links'>
          <a className='link' href={privacyPolicy} target='_blank' rel='noreferrer'>Privacy policy</a>
          <a className='link' href={refundsAndCancellation} target='_blank' rel='noreferrer'>Refunds & Cancellation</a>
          <a className='link' href={contact} target='_blank' rel='noreferrer'>Contact us</a>
          <a className='link' href={terms} target='_blank' rel='noreferrer'>Terms of use</a>
        </div>

        <div className='footer-title'>
          <span>&copy; {title}</span>
          <span style={{fontSize:'0.7rem'}}>Powered by <a className='link' style={{margin:0}} href='https://envoq.in' target='_blank' rel='noreferrer'>ENVOQ</a></span>
        </div>
      </div>
    </div>
  )
}


export default App;
