const title = 'Shree Ganesh Italian Granites & Marbles';
const webTitle = 'SG Marbles';
const website = 'www.sgmarbles.in';
const subtitle = null;
const bz_email = 'support@sgmarbles.in';
const telephone = '+917011671004';
const showTelephone = '(+91) 7011-671-004';
const registeredAddress = 'DSS 120, Sector-21C, Marble Market, Faridabad, Haryana, 121008'
const razorpayPopupTitleForOnline = 'sgmarbles.in';

//privacy policy requirement
const websiteURL = 'https://www.sgmarbles.in';
const complaintsEmail = 'support@sgmarbles.in';
const unsubscribeFromPromoEmailsURL = 'https://www.sgmarbles.in/account/notifications/communication-preferences';
const grievanceOfficerName = 'Mr. Ravi Sharma';
const grievanceOfficerEmail = 'grievance.officer@sgmarbles.in';
const supportTeamEmail = 'support@sgmarbles.in'

const privacyPolicy = 'https://sgmarbles.in/privacy-policy';
const terms = 'https://sgmarbles.in/terms';
const contact = 'https://sgmarbles.in/contact';
const refundsAndCancellation = 'https://sgmarbles.in/help/refunds-cancellation';
const myOrders = 'https://sgmarbles.in/account/orders';

// Free cancellation period after order is paid in (hrs)
const freeCancellationPeriod = 0;
//terms of use requirement
const jurisdiction = 'Faridabad(HR)';

//img server for shop
const imgSrvShop = "https://imgcloud.cf/client/in_sgmarbles/products";
const imgSrv = "https://imgcloud.cf/client/in_sgmarbles/banners";

const API_URL = "http://localhost:5000/sgmarbles/us-central1/api";
const API_BASE_URL = "http://localhost:5000";

export { title, subtitle, bz_email, telephone, showTelephone, 
    webTitle, website, websiteURL, complaintsEmail, unsubscribeFromPromoEmailsURL, freeCancellationPeriod,
    grievanceOfficerEmail, grievanceOfficerName, supportTeamEmail, registeredAddress, jurisdiction, 
    privacyPolicy, terms, contact, razorpayPopupTitleForOnline, imgSrv, imgSrvShop, myOrders, refundsAndCancellation,
    API_URL, API_BASE_URL
};